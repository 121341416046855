<template>
  <div class="page p-grid">
    <div class="p-col-12">
      <h5>修改企业信息</h5>
      <div class="p-col-12">
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-4">
            <div>
              <label>企业名称:</label>
              <InputText class="p-mt-2" v-model="data.companiesName" placeholder="企业名"/>
            </div>
          </div>
          <div class="p-col-12 p-md-4">
            <div>
              <label>统一信用代码:</label>
              <InputText v-model="data.creditCode" class="p-mt-2" placeholder="输入信用代码"/>
            </div>
          </div>

          <div class="p-col-12 p-md-4">
            <div>
              <label>所属行业:</label>
              <InputText class="p-mt-2" v-model="data.industry"/>
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div>
              <label>企业联系人:</label>
              <InputText v-model="data.juridicalPerson" class="p-mt-2" placeholder="联系人"/>
            </div>
          </div>

          <div class="p-col-12 p-md-6">
            <div>
              <label>电话号码:</label>
              <InputText v-model="data.phone" class="p-mt-2" placeholder="电话号码"/>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <div>
          <label>编辑企业简介：</label>
          <div class="p-mt-2">
            <my-editor :value="data.brief" v-on:input="setContent"></my-editor>
          </div>
        </div>
      </div>
      <div class="p-grid p-col-12 p-mt-6 p-jc-around">
        <Button @click="goto()" label="取消编辑" class="p-button-outlined"/>
        <!--                <Button @click="delCompaies(data.id)" label="移除企业" class="p-button-outlined" />-->
        <!--                <Button label="置顶展示" class="p-button-outlined" />-->
        <Button @click="editCompanies()" label="保存并编辑" class="p-button-outlined"/>
      </div>
    </div>
  </div>
  <Toast/>
</template>

<script>
import myEditor from "@/views/backend/myEditor";

export default {
  name: "",
  components: {myEditor},
  data() {
    return {
      data: {
        id: null,
        userId: null,
        companiesName: null,
        creditCode: null,
        industry: null,
        juridicalPerson: null,
        phone: null,
        brief: null,
      },
      selectedIndustry: null,
      industry: [
        {name: '互联网', code: '1'},
        {name: '金融', code: '2'},
        {name: '服装贸易', code: '3'},
        {name: '旅游服务行业', code: '4'},
        {name: '酒店服务行业', code: '5'},
        {name: '采矿业', code: '6'},
        {name: '制造业', code: '7'},
        {name: '电力、热力、燃气及水的生产和供应业', code: '8'},
        {name: '其他', code: '9'}
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    setContent(val){
      this.data.brief = val
    },
    goto() {
      this.$router.push('/enterpriseList')
    },
    getData() {
      let id = this.$route.query.id
      this.$http.get('/companies/getInformation?id=' + id).then((res) => {
        console.log(res.data)
        this.data = res.data
      })
    },

    /*delCompaies(id){
      this.$http.post('/companies/delCompaies?id='+id).then(()=>{
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.$router.push('/EnterpriseList')
      })
    },*/
    editCompanies() {
      let my = this
      this.$http.post('/companies/editCompanies', this.data).then(() => {
        this.$toast.add({severity: 'success', summary: '成功', detail: '操作成功', life: 3000})
        setTimeout(function () {
          my.$router.push('/enterpriseList')
        }, 1000);

      })
    }
  },
}
</script>

<style scoped>

</style>
